import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Navbar, Footer, PreNewsletter, Newsletter, FloatingNewsletter } from '..'

export const Page = ({children, newsletterRef, footerRef}) => {
  return (
    <div>
			<div>
				<Navbar />
        <FloatingNewsletter />

				<main className="container">
          {children}
				</main>

        <PreNewsletter />
				<Newsletter parentRef={newsletterRef}/>
				<Footer parentRef={footerRef}/>
			</div>
    </div>
  )
}

export const Head = ({title, children}) => {
  return (
    <Helmet>
      <title>{title ?? "iLovee"}</title>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="author" content="Lucas Henrique <eu@lhenrique.com.br>" />
      <link rel="icon" href="/favicon.ico" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Um espaço de ideias, trocas e múltiplos olhares femininos para falar de bem-estar, moda, cultura e muito mais. Junte-se a nós!" />

      {children}

      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-#######');`,
        }}
      ></script>
    </Helmet>
  )
}
