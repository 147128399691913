import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { client } from '../../services/api'
import styles from './style.module.scss'

import post01 from '../../assets/temp/side01.svg'
import post02 from '../../assets/temp/side02.svg'
import post03 from '../../assets/temp/side03.svg'
import post04 from '../../assets/temp/side04.svg'
import post05 from '../../assets/temp/side05.svg'
import post06 from '../../assets/temp/side06.svg'

export const Sidebar = (props) => {
  const [ items, setItems ] = useState()

  useEffect(() => {
    client.getEntries({content_type: "blogPost", limit: 6})
      .then((res) => setItems(res.items))
      .catch(e => console.error(e))
  }, [])

  return (
    <div className={styles.sidebar}>
      <p className={styles['sidebar__title']}><strong>NÃO PERCA</strong> | As mais lidas</p>
      <hr />

      {
        items && items.length !== 0 &&
          items.map((item, index) => {
            return <Post key={`post_${index}`} {...item} />
          })
      }

      <hr />
    </div>
  )
}

const Post = (props) => {
  const date = props.fields?.publishDate ? props.fields?.publishDate.split('T')[0] : props?.sys.createdAt.split('T')[0]
  const day = date.split('-')[2]
  const month = date.split('-')[1]
  const year = date.split('-')[0]

  return (
    <a href={`/materia/${year}/${month}/${day}/${props.fields.slug}`}>
      <article className={styles.post}>
        <div className={styles['post__image']}>
          <img src={props.fields?.defaultImageHorizontal?.fields.file.url ?? props.fields?.defaultImageVertical?.fields.file.url} />
        </div>
        <div className={styles.content}>
          <p className={styles.category}>{(props.metadata.tags || props.metadata.tags.length > 0) && props.metadata.tags[0]?.sys.id}</p>
          <h1 className={styles.title}>{props.fields.title}</h1>
        </div>
      </article>
    </a>
  )
}
