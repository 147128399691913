import React from 'react'
import { Link } from 'react-router-dom'
import textRandomizer from '../../../helpers/buttonTextSelector'
import styles from './style.module.scss'

export const BlueCardPost = ({metadata, fields, sys}) => {
  const date = fields?.publishDate ? fields?.publishDate.split('T')[0] : sys.createdAt.split('T')[0]
  const day = date.split('-')[2]
  const month = date.split('-')[1]
  const year = date.split('-')[0]

	return (
    <Link to={`/materia/${year}/${month}/${day}/${fields.slug}`}>
      <article className={`d-flex align-center justify-center ${styles['post--blue']}`}>
        <div className={styles.content}>
          <div className={styles.post}>
            <div className={styles['card--blue']}>
              <img
                src={fields?.defaultImageHorizontal?.fields.file.url ?? fields?.defaultImageVertical?.fields.file.url}
                className={`${styles.content__image} ${styles['hide-desk']}`}
                width="780"
                height="550"
              />

              <div>
                {
                  metadata.tags && metadata.tags.length !== 0 &&
                    <p className={styles.content__category}>{(metadata.tags || metadata.tags.length > 0) && metadata.tags[0]?.sys.id}</p>
                }

                <h1 className={styles.content__title}>{fields.title}</h1>

                <p className={styles.content__subtitle}>{fields.subtitle}</p>

                <p className={styles.content__author}>Por {fields.author.fields.name}</p>

                <button type="button" className="button button--white-outlined button--black-outline__mobile">
                  {textRandomizer()}
                </button>
              </div>
            </div>
          </div>
          <div className={styles.image}>
            <img
              src={fields?.defaultImageHorizontal?.fields.file.url ?? fields?.defaultImageVertical?.fields.file.url}
              className={`${styles.content__image} ${styles['hide-mobile']}`}
              width="780"
              height="550"
            />
          </div>
        </div>
      </article>
    </Link>
	)
}
