export function localStorageExpires()
{
    var toRemove = [],
        currentDate = new Date().getTime();

    for (var i = 0, j = localStorage.length; i < j; i++) {
       var key = localStorage.key(i),
           itemValue = localStorage.getItem(key);

       if (itemValue && /^\{(.*?)\}$/.test(itemValue)) {

            var current = JSON.parse(itemValue);

            if (current.expires && current.expires <= currentDate) {
                toRemove.push(key);
            }
       }
    }

    for (var i = toRemove.length - 1; i >= 0; i--) {
        localStorage.removeItem(toRemove[i]);
    }
}

localStorageExpires();

export function setLocalStorage(chave, valor, minutos)
{
    var expirarem = new Date().getTime() + (60000 * minutos);

    localStorage.setItem(chave, JSON.stringify({
        "value": valor,
        "expires": expirarem
    }));
}

export function getLocalStorage(chave)
{
    localStorageExpires();

    var itemValue = localStorage.getItem(chave);

    if (itemValue && /^\{(.*?)\}$/.test(itemValue)) {

        var current = JSON.parse(itemValue);

        return current.value;
    }
}
