import { useRef } from 'react'
import { ChevronRightIcon, CrossIcon } from '../../icons'
import styles from './style.module.scss'

export const Newsletter = ({parentRef}) => {
	const submit = (e) => {
		e.preventDefault();
	}

	return (
		<section className={styles.newsletter} ref={parentRef}>
			<div className="container">
				<h1 className={styles.newsletter__title}>ASSINE NOSSA NEWSLETTER</h1>

				<form method="post" onSubmit={submit} className={`align-center ${styles.input__group}`}>
					<input type="email" name="email" id="email" autoComplete="off" />
					<label htmlFor="email">E-mail</label>
					<ChevronRightIcon className={styles.input__addon} />
				</form>
			</div>
		</section>
	)
}

export const FloatingNewsletter = () => {
  const newsletter = useRef();

  const submit = (e) => {
    e.preventDefault();
  }

  const closeNewsletter = () => {
    newsletter.current.style.display = "none";
    localStorage.setItem('newsletter-refused', true);
  }

  return (<>
    {
      !localStorage.getItem('newsletter-refused') &&
      <div ref={newsletter} className={`${styles.newsletter} ${styles['newsletter--floating']}`}>
        <div className={styles['newsletter--floating__wrapper']}>
          <button type="button" className={styles['newsletter--floating__close']} onClick={closeNewsletter}>
            <CrossIcon />
          </button>

          <h1 className={styles.newsletter__title}>ASSINE NOSSA NEWSLETTER</h1>

          <form method="post" onSubmit={submit} className={`align-center ${styles.input__group}`}>
            <input type="email" name="email" id="email" autoComplete="off" />
            <label htmlFor="email">E-mail</label>
            <ChevronRightIcon className={styles.input__addon} />
          </form>
        </div>

        <div className={`${styles.bubble} animation-jump--fast`}></div>
        <div className={styles['box--white']}></div>
      </div>
    }
  </>)
}
