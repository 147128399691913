import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Page, Head, Author, Sidebar } from '../../components'
import { client } from '../../services/api'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { MARKS, BLOCKS } from '@contentful/rich-text-types'
import dateParser from '../../helpers/dateParser'
import styles from './style.module.scss'

export default function Article() {
  const [ article, setArticle ] = useState()
  const { slug } = useParams()
  const content = useRef()

  useEffect(() => {
    window.scrollTo(0, 0);

    client.getEntries({
      content_type: "blogPost",
      'fields.slug[in]': slug
    })
      .then(response => {
        setArticle(response.items[0])
        window.instgrm?.Embeds?.process();
      })
      .catch(e => console.error(e))
  }, [])

  const postOptions = {
    renderMark: {
      [MARKS.CODE]: embedded => <div dangerouslySetInnerHTML={{__html: embedded}}></div>
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({data: {target: {fields}}}) =>
        <div className={styles['image-group']} dangerouslySetInnerHTML={{__html: `<img src="${fields.file.url}?fm=webp&fit=thumb" alt="${fields.description}"/>`}}></div>
    }
  }

  return (
    <Page>
      {
        article && <>
          <Head title={article.fields.title}></Head>

          <hr className={styles.line} />

          <div className="d-flex w-100">
            <article className={styles.content}>
              <div ref={content}>
                <header className={styles.title}>
                  <p className={styles.category}>{(article.metadata.tags || article.metadata.tags.length > 0) && article.metadata.tags[0]?.sys.id}</p>
                  <h1>{article.fields.title}</h1>
                  <p className={styles.subtitle}>{article.fields.subtitle}</p>
                  <p className={styles.author}><i>Por</i> {article.fields.author.fields.name}</p>
                  <p className={styles.date}>{dateParser(article.fields?.publishDate ?? article?.sys.createdAt)}</p>
                </header>

                <div className={styles.image}>
                  <img src={article.fields?.defaultImageHorizontal?.fields.file.url ?? article.fields?.defaultImageVertical?.fields.file.url}/>
                </div>

                <div className={styles.text}>
                  {documentToReactComponents(article.fields.content, postOptions)}
                </div>
              </div>

              <Author {...article.fields.author} />
            </article>

            <hr className={styles['line__vertical']} style={{height: `calc(${content.current?.offsetHeight}px`}}/>

            <Sidebar />
          </div>
        </>
      }
    </Page>
  )
}
