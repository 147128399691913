import React from 'react'
import { Link } from 'react-router-dom'
import logoImage from '../../assets/ilovee_logo_black.svg'
import styles from './style.module.scss'

export default function NotFound() {
	return (
		<div className={styles.notfound}>
      <img src={logoImage} className={styles.logo}/>

      <div className={styles.content}>
        <p>Uh oh...</p>
        <h1>404</h1>
        <p>Página não encontrada.</p>

        <Link to="/" className="button button--black-outlined">
          Voltar a página inicial
        </Link>
      </div>
    </div>
	)
}
