import { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { Page, Head, Spotlight, GreenCardPost, WhiteCardPost, BlueCardPost, DoubleColorPost } from '../../components'

import { useWindowSize } from '../../hooks/useWindowSize'

import { client } from '../../services/api'

import postImage01 from '../../assets/temp/post_left.svg'
import postImage02 from '../../assets/temp/post_middle.svg'
import postImage03 from '../../assets/temp/post_right.svg'

import bgPostImage01 from '../../assets/post_aligned_left.svg'
import bgPostImage02 from '../../assets/post_aligned_center.svg'
import bgPostImage03 from '../../assets/post_aligned_right.svg'

import "slick-carousel/slick/slick.css";
import styles from './style.module.scss'

export default function Home() {
  const { width } = useWindowSize()
  const [ spotlight, setSpotlight ] = useState()
  const [ posts, setPosts ] = useState([])

  const [ skipMorePosts, setSkipMorePosts ] = useState(0)
  const [ gettingMorePosts, setGettingMorePosts ] = useState(false)
  const [ morePosts, setMorePosts ] = useState([])
  const [ postsError, setPostsError ] = useState(false)

  const newsletterRef = useRef()
  const footerRef = useRef()

  const sliderSettings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10%",
    infinite: false
  }

  useEffect(() => {
    async function getPosts() {
      const spotlight = await client.getEntries({
        content_type: "homepage",
        limit: 1
      })

      const posts = await client.getEntries({
        content_type: "blogPost",
        limit: 9,
        "sys.id[nin]": spotlight.items[0].fields.spotlight?.sys.id
      })

      setSpotlight(spotlight.items[0])
      setPosts(posts.items)
    }
    getPosts()

    // let getMorePosts = true
    // let skipPosts = 0
    // window.addEventListener('scroll', async function() {
    //   const newsHeight = newsletterRef.current?.offsetHeight;
    //   const footerHeight = footerRef.current?.offsetHeight;

    //   if (((window.innerHeight + window.scrollY) + (newsHeight + footerHeight)) >= document.body.offsetHeight) {
    //     if ( !getMorePosts ) return
    //     getMorePosts = false

    //     client.getEntries({
    //       content_type: "blogPost",
    //       skip: (7 + skipPosts),
    //       limit: 3
    //     })
    //     .then(response => {
    //       const {items} = response
    //       getMorePosts = true
    //       skipPosts = skipPosts + 3
    //       setMorePosts({...morePosts, items})
    //     })
    //     .catch(() => {
    //       setPostsError(true)
    //     })
    //   }
    // })
  }, [posts, spotlight, morePosts])

  return (
    <Page footerRef={footerRef} newsletterRef={newsletterRef}>
      <Head title="Home - iLovee"></Head>

      {
        spotlight &&
          <Spotlight {...spotlight.fields.spotlight}/>
      }

      {
        posts && posts.length !== 0 && <>
          <div className={`d-flex ${styles.wrapper}`}>
            { posts[0] && <WhiteCardPost {...posts[0]}/> }
            { posts[1] && <GreenCardPost {...posts[1]}/> }
          </div>

          <div className="d-flex w-100">
            { posts[2] && <BlueCardPost {...posts[2]}/> }
          </div>

          <div className={`d-flex ${styles['wrapper-carousel']}`}>
            {
              width <= 1199 ?
                <Slider {...sliderSettings}>
                  { posts[3] && <DoubleColorPost {...posts[3]} image={postImage01} background={bgPostImage01}/> }
                  { posts[4] && <DoubleColorPost {...posts[4]} image={postImage02} background={bgPostImage02}/> }
                  { posts[5] && <DoubleColorPost {...posts[5]} image={postImage03} background={bgPostImage03}/> }
                </Slider>
              : <>
                  { posts[3] && <DoubleColorPost {...posts[3]} image={postImage01} background={bgPostImage01}/> }
                  { posts[4] && <DoubleColorPost {...posts[4]} image={postImage02} background={bgPostImage02}/> }
                  { posts[5] && <DoubleColorPost {...posts[5]} image={postImage03} background={bgPostImage03}/> }
                </>
            }
          </div>

          <div className={`d-flex ${styles['wrapper-carousel']}`}>
            {
              width <= 1199 ?
                <Slider {...sliderSettings}>
                  { posts[6] && <DoubleColorPost {...posts[6]} image={postImage01} background={bgPostImage01}/> }
                  { posts[7] && <DoubleColorPost {...posts[7]} image={postImage02} background={bgPostImage02}/> }
                  { posts[8] && <DoubleColorPost {...posts[8]} image={postImage03} background={bgPostImage03}/> }
                </Slider>
              : <>
                  { posts[6] && <DoubleColorPost {...posts[6]} image={postImage01} background={bgPostImage01}/> }
                  { posts[7] && <DoubleColorPost {...posts[7]} image={postImage02} background={bgPostImage02}/> }
                  { posts[8] && <DoubleColorPost {...posts[8]} image={postImage03} background={bgPostImage03}/> }
                </>
            }
          </div>

          <div className={`d-flex align-center justify-center ${styles.btn}`}>
            <Link to={`/materias`} className="button button--black">
              Ver todas as matérias
            </Link>
          </div>

          {/* {
            morePosts && morePosts.length !== 0 && morePosts.items && morePosts.items.length !== 0 &&
              <div className="d-flex">
                {console.log(morePosts)}
                {
                  morePosts.items.map(post => {
                    return (
                      <div>
                        Olá
                      </div>
                    )
                  })
                }
              </div>
          } */}
        </>
      }
    </Page>
  )
}
