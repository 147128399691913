import { ContentfulClient } from 'react-contentful';

export const client = ContentfulClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
});

export const clientPreview = ContentfulClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  accessToken: process.env.REACT_APP_CONTENTFUL_API_PREVIEW,
  host: "preview.contentful.com"
})
