import { ChevronRightIcon } from '../../icons'
import logoBlack from '../../assets/ilovee_logo_black.svg'
import { Facebook, Instagram, Youtube } from '../../icons'
import styles from './style.module.scss'

export const PreNewsletter = () => {
	return (
		<section className={styles.prenewsletter}>
			<div className="container">
        <div className="d-flex w-100">
          <div className={styles.column}>
            <img src={logoBlack} className={styles.logo} alt="Logo escrito iLovee em tom preto único"/>

            <div className={`${styles["ball"]} ${styles["ball--red"]} animation-jump--fast`}></div>
            <div className={`${styles["ball"]} ${styles["ball--pink"]} animation-jump--slow`}></div>
          </div>
          <div className={`${styles.column} justify-right`}>
            <ul className={styles.social}>
              <li><a href="#"><Facebook /></a></li>
              <li><a href="#"><Youtube /></a></li>
              <li><a href="#"><Instagram /></a></li>
            </ul>
          </div>
        </div>
			</div>
		</section>
	)
}
