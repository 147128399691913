const config = {
  url: process.env.REACT_APP_CONTENTFUL_API_URL || "https://api.contentful.com",
  token: process.env.REACT_APP_CONTENTFUL_API_TOKEN || "",
  space: process.env.REACT_APP_CONTENTFUL_SPACE || "",
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "homolog"
}

async function getContentfulTags() {
  try {
    const { url, space, environment, token } = config
    const path = `${url}/spaces/${space}/environments/${environment}/tags?skip=0&access_token=${token}`
    const tags = await fetch(path, {method: "GET"})

    return tags.json()
  } catch (e) {
    console.error(e)
  }
}

export default getContentfulTags
