import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './Route';

import Home from '../pages/Home'
import Articles from '../pages/Articles'
import Article from '../pages/Article'
import Preview from '../pages/Preview'
import NotFound from '../pages/NotFound'

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/materias/:category" exact component={Articles} />
        <Route path="/materias" exact component={Articles} />
        <Route path="/pesquisa/:term" exact component={Articles} />
        <Route path="/materia/:year/:month/:day/:slug" exact component={Article} />

        <Route path="/preview/:post_id" exact component={Preview} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
