import React from 'react'
import { Facebook, Instagram, Youtube } from '../../icons'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styles from './style.module.scss'

export const Author = (props) => {
  return (
    <div className={styles.author}>
      <div className={styles.image}>
        <img src={props.fields.picture.fields.file.url} />
      </div>
      <div className={styles.content}>
        <p className={styles.name}>Por {props.fields.name}</p>
        <div className={styles.description}>
          {documentToReactComponents(props.fields.description)}
        </div>

        {
          props.fields.social &&
            <ul className={styles.social}>
              {
                props.fields.social.map(item => {
                  if ( !item.key ) return

                  return <li key={item.id}>
                    <a href={item.value}>
                      { item.key === "facebook" && <Facebook /> }
                      { item.key === "instagram" && <Instagram /> }
                      { item.key === "youtube" && <Youtube /> }
                    </a>
                  </li>
                })
              }
            </ul>
        }
      </div>
    </div>
  )
}
