export const CrossIcon = ({className}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20.468" height="20.468" viewBox="0 0 20.468 20.468">
      <g id="Grupo_146" data-name="Grupo 146" transform="translate(-1030.081 -812.08)">
        <g id="Grupo_145" data-name="Grupo 145" transform="translate(1032.275 814.274)">
          <path id="Caminho_180" data-name="Caminho 180" d="M12618.433,917.284l15.547,15.547" transform="translate(-12618.433 -917.284)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
          <path id="Caminho_181" data-name="Caminho 181" d="M0,0,15.547,15.547" transform="matrix(0.035, 0.999, -0.999, 0.035, 15.537, 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3"/>
        </g>
      </g>
    </svg>
  )
}
