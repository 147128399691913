import React from 'react'
import { Link } from 'react-router-dom'
import textRandomizer from '../../../helpers/buttonTextSelector'
import styles from './style.module.scss'

export const DoubleColorPost = (props) => {
  const { metadata, fields, sys } = props
  const date = fields?.publishDate ? fields?.publishDate.split('T')[0] : sys.createdAt.split('T')[0]
  const day = date.split('-')[2]
  const month = date.split('-')[1]
  const year = date.split('-')[0]

	return (
    <Link to={`/materia/${year}/${month}/${day}/${fields.slug}`} className={`${styles['post--card']}`}>
      <div data-image="true" className={styles.image} style={{backgroundImage: `url(${props.background})`}}>
        <img src={fields?.defaultImageVertical?.fields.file.url ?? fields?.defaultImageHorizontal?.fields.file.url} />
      </div>

      <div className={styles.content}>
        {
          metadata.tags && metadata.tags.length !== 0 &&
            <p className={styles.content__category}>{(metadata.tags || metadata.tags.length > 0) && metadata.tags[0]?.sys.id}</p>
        }

        <h1 className={styles.content__title}>{fields.title}</h1>

        <p className={styles.content__subtitle}>{fields.subtitle}</p>

        <p className={styles.content__author}>Por {fields.author.fields.name}</p>

        <button type="button" className="button button--black-outlined">
          {textRandomizer()}
        </button>
      </div>
    </Link>
	)
}
