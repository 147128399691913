export const SearchIcon = ({className}) => {
	return (
		<svg className={className ?? ''} xmlns="http://www.w3.org/2000/svg" width="19.529" height="16.967" viewBox="0 0 19.529 16.967">
			<g id="Grupo_101" dataname="Grupo 101" transform="translate(0.028)">
				<g id="Elipse_1" dataname="Elipse 1" transform="translate(-0.028 0)" fill="none" stroke="#000" strokeWidth="2">
					<ellipse cx="7.735" cy="7.413" rx="7.735" ry="7.413" stroke="none"/>
					<ellipse cx="7.735" cy="7.413" rx="6.735" ry="6.413" fill="none"/>
				</g>
				<path id="Caminho_32" dataname="Caminho 32" d="M1741.207,147.162l5.337,3.638" transform="translate(-1728.433 -135.223)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2"/>
			</g>
		</svg>
	)
}