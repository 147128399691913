import React from 'react'
import { Link } from 'react-router-dom'
import textRandomizer from '../../helpers/buttonTextSelector'
import styles from './style.module.scss'

export const Spotlight = (props) => {
  const { metadata, fields } = props
  const date = fields?.publishDate ? fields?.publishDate.split('T')[0] : props?.sys.createdAt.split('T')[0]
  const day = date.split('-')[2]
  const month = date.split('-')[1]
  const year = date.split('-')[0]

	return (
    <Link to={`/materia/${year}/${month}/${day}/${fields.slug}`} className={styles.wrapper}>
      <div
        className={styles.spotlight}
        style={{backgroundImage: `url(${fields?.defaultImageHorizontal?.fields.file.url ?? fields?.defaultImageVertical?.fields.file.url})`}}
      >
        <div className={styles.content}>
          <p className={styles.content__category}>{(metadata.tags || metadata.tags.length > 0) && metadata.tags[0]?.sys.id}</p>
          <h1 className={styles.content__title}>{fields.title}</h1>
          <p className={styles.content__subtitle}>{fields.subtitle}</p>

          <button tyé="button" to={`/materia/${year}/${month}/${day}/${fields.slug}`} className="button button--white-outlined">
            {textRandomizer()}
          </button>
        </div>
      </div>

      <div className={`${styles.content} ${styles['content-mobile']}`}>
        <p className={styles.content__category}>{(metadata.tags || metadata.tags.length > 0) && metadata.tags[0]?.sys.id}</p>
        <h1 className={styles.content__title}>{fields.title}</h1>
        <p className={styles.content__subtitle}>{fields.subtitle}</p>

        <button type="button" className="button button--black-outlined">
          {textRandomizer()}
        </button>
      </div>
    </Link>
	)
}
