import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { client } from '../../services/api'
import { Page, Head } from '../../components'
import styles from './style.module.scss'

export default function Articles() {
  const [ items, setItems ] = useState()
  const [ pagination, setPagination ] = useState(1)
  const [ totalPosts, setTotalPosts ] = useState(0)

  const { category, term } = useParams()
  const paginationParentElem = useRef()

  const getPosts = (query) => {
    client.getEntries(query)
      .then((res) => {
        setItems(res.items)
        setTotalPosts(Math.floor(res.total / 9))
      })
      .catch(e => console.error(e))
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    let query = {
      content_type: "blogPost",
      limit: 9,
      skip: pagination
    }

    if ( category !== "materias" ) {
      if ( term ) {
        query['fields.title[match]'] = term
      } else {
        query['metadata.tags.sys.id[in]'] = category
      }
    }

    getPosts(query)
  }, [])

  const changePage = (elem, pageNumber) => {
    window.scrollTo(0, 0);

    let query = {
      content_type: "blogPost",
      limit: 9,
      skip: 9 * pageNumber - pageNumber
    }

    paginationParentElem.current.querySelector('li[data-active="true"]').removeAttribute('data-active')

    if ( elem.target ) {
      elem.target.setAttribute('data-active', 'true')
    } else {
      paginationParentElem.current.querySelectorAll('li')[pageNumber].setAttribute('data-active', 'true')
    }

    getPosts(query)
    setPagination(pageNumber)
  }

  return (
    <Page>
      <Head title="Matérias - iLovee"/>

      {
        items && items.length !== 0 &&
          <main className="container" style={{marginTop: "15px"}}>
            <div className={`d-flex ${styles['wrapper']}`}>
              {
                items.map(item => {
                  return <Post key={item.sys.id} {...item} />
                })
              }
            </div>

            {
              totalPosts / 9 > 0 &&
                <div className="d-flex justify-center align-center" style={{marginTop: "40px"}}>
                  <ul className={styles.pagination} ref={paginationParentElem}>
                    {
                      [...Array(totalPosts)].map((x, i) => (
                        <li onClick={(e) => changePage(e, i + 1)} data-active={i === 0 && 'true'}>
                          {i + 1}
                        </li>
                      ))
                    }
                  </ul>
                </div>
            }
          </main>
      }
    </Page>
  )
}

const Post = (props) => {
  const { metadata, fields, sys } = props
  const date = fields?.publishDate ? fields?.publishDate.split('T')[0] : sys.createdAt.split('T')[0]
  const day = date.split('-')[2]
  const month = date.split('-')[1]
  const year = date.split('-')[0]

  return (
    <Link to={`/materia/${year}/${month}/${day}/${fields.slug}`} className={styles.post}>
      <div className={styles.image}>
        <p className={styles.category}>{(metadata.tags || metadata.tags.length > 0) && metadata.tags[0]?.sys.id}</p>

        <img src={fields.defaultImageHorizontal?.fields.file.url ?? fields.defaultImageVertical?.fields.file.url}></img>
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>{fields.title}</h1>
        <p className={styles.subtitle}>{fields.subtitle}</p>
        <p className={styles.author}>Por {fields.author.fields.name}</p>

        <button type="button" className="button button--black-outlined">
          ler mais
        </button>
      </div>
    </Link>
  )
}
