function textRandomizer() {
  const items = [
    "ler mais",
    "clique aqui",
    "saiba mais",
    "leia mais",
    "continuar lendo"
  ]

  const selectedIndex = items[Math.floor(Math.random() * items.length)]

  return selectedIndex
}

export default textRandomizer
