import React, { useEffect, useState } from 'react'
import { client } from '../../services/api'
import { setLocalStorage, getLocalStorage } from '../../helpers/localStorage'
import { Facebook, Youtube, Instagram } from '../../icons'
import styles from './style.module.scss'

export const Footer = ({parentRef}) => {
  const [ items, setItems ] = useState()

  useEffect(() => {
    if ( getLocalStorage('footer') )
        return setItems(getLocalStorage('footer'))

    client.getEntries({content_type: "footer"})
      .then((res) => {
        setItems(res.items[0].fields)
        setLocalStorage('footer', res.items[0].fields, 60*24)
      })
      .catch(e => console.error(e))
  }, [])

	return (
		<footer className={styles.footer} ref={parentRef}>
			<div className="container">
        <div className={`${styles['hide-desk']} d-flex w-100`}>
          <ul className={styles.social}>
            <li><a href="https://www.instagram.com/ilove.e/" target="_blank"><Instagram /></a></li>
          </ul>
        </div>

				<div className={`${styles.footer__items} d-flex w-100`}>
					<div className={styles.items__column}>
            {
              items?.firstColumnTitle &&
                <p>{items?.firstColumnTitle}</p>
            }

            {
              items?.firstColumnItems &&
                <ul>
                  {
                    items?.firstColumnItems.map(item => {
                      return <li key={item.id}><a href={item.value}>{item.key}</a></li>
                    })
                  }
                </ul>
            }
					</div>
					<div className={styles.items__column}>
            {
              items?.secondColumnTitle &&
                <p>{items?.secondColumnTitle}</p>
            }

            {
              items?.secondColumnItems &&
                <ul>
                  {
                    items?.secondColumnItems.map(item => {
                      return <li key={item.id}><a href={item.value}>{item.key}</a></li>
                    })
                  }
                </ul>
            }
					</div>
					<div className={styles.items__column}>
            {
              items?.tertiaryColumnTitle &&
						    <p>{items?.tertiaryColumnTitle}</p>
            }

            {
              items?.thirdColumnItems &&
                <ul>
                  {
                    items?.thirdColumnItems.map(item => {
                      return <li key={item.id}><a href={item.value}>{item.key}</a></li>
                    })
                  }
                </ul>
            }
					</div>
					<div className={styles.items__column}>
            {
              items?.fourtyColumnTitle &&
                <p>{items?.fourtyColumnTitle}</p>
            }

						{
              items?.fourtyColumnItems &&
                <ul>
                  {
                    items?.fourtyColumnItems.map(item => {
                      return <li key={item.id}><a href={item.value}>{item.key}</a></li>
                    })
                  }
                </ul>
            }
					</div>
				</div>

				<hr />

				<div className="d-flex w-100 align-center justify-center">
					<ul className={styles.list__items}>
						{/* <li><a href="#">CONTATO</a></li> */}
						<li><a href="https://www.instagram.com/p/CHp5LlCnIkO/">SOBRE NÓS</a></li>
						{/* <li><a href="#">TERMOS DE USO</a></li> */}
					</ul>
				</div>
			</div>
		</footer>
	)
}
