export const Facebook = ({className}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="25.307" height="25.307" viewBox="0 0 25.307 25.307">
      <path id="Caminho_77" dataname="Caminho 77" d="M23.909,0H1.4A1.4,1.4,0,0,0,0,1.4V23.91a1.4,1.4,0,0,0,1.4,1.4h12.12v-9.8h-3.3v-3.82h3.3V8.871c0-3.268,2-5.049,4.912-5.049a27.352,27.352,0,0,1,2.946.15V7.388H19.352c-1.586,0-1.891.754-1.891,1.859v2.438h3.783l-.495,3.819H17.46v9.8h6.448a1.4,1.4,0,0,0,1.4-1.4V1.4A1.4,1.4,0,0,0,23.909,0Z" transform="translate(0 -0.001)"/>
    </svg>
  )
}

export const Youtube = ({className}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="36.125" height="25.293" viewBox="0 0 36.125 25.293">
      <path id="_001-youtube" dataname="001-youtube" d="M35.381-2.124A4.526,4.526,0,0,0,32.2-5.309c-2.827-.773-14.135-.773-14.135-.773s-11.307,0-14.134.744A4.617,4.617,0,0,0,.744-2.124C0,.7,0,6.565,0,6.565s0,5.892.744,8.689a4.526,4.526,0,0,0,3.184,3.184c2.856.774,14.135.774,14.135.774s11.307,0,14.134-.744a4.526,4.526,0,0,0,3.184-3.184c.744-2.827.744-8.689.744-8.689S36.155.7,35.381-2.124ZM14.462,11.98V1.149l9.4,5.416Zm0,0" transform="translate(0 6.082)"/>
    </svg>
  )
}

export const Instagram = ({className}) => {
  return (
    <svg className={className} id="_002-instagram" dataname="002-instagram" xmlns="http://www.w3.org/2000/svg" width="25.302" height="25.307" viewBox="0 0 25.302 25.307">
      <path id="Caminho_73" dataname="Caminho 73" d="M25.687,7.44A9.247,9.247,0,0,0,25.1,4.37a6.483,6.483,0,0,0-3.7-3.7,9.271,9.271,0,0,0-3.07-.588C16.971.015,16.541,0,13.105,0S9.239.015,7.889.074A9.25,9.25,0,0,0,4.82.662,6.175,6.175,0,0,0,2.575,2.126a6.231,6.231,0,0,0-1.458,2.24,9.272,9.272,0,0,0-.588,3.07C.464,8.79.449,9.22.449,12.656s.015,3.866.074,5.216a9.247,9.247,0,0,0,.588,3.07,6.482,6.482,0,0,0,3.7,3.7,9.272,9.272,0,0,0,3.07.588c1.349.059,1.78.074,5.216.074s3.866-.015,5.216-.074a9.245,9.245,0,0,0,3.07-.588,6.473,6.473,0,0,0,3.7-3.7,9.278,9.278,0,0,0,.588-3.07c.059-1.35.074-1.78.074-5.216S25.746,8.79,25.687,7.44ZM23.408,17.773a6.936,6.936,0,0,1-.435,2.348,4.2,4.2,0,0,1-2.4,2.4,6.961,6.961,0,0,1-2.348.435c-1.335.059-1.735.074-5.112.074S9.328,23.018,8,22.958a6.933,6.933,0,0,1-2.348-.435A3.894,3.894,0,0,1,4.2,21.579a3.934,3.934,0,0,1-.944-1.453,6.962,6.962,0,0,1-.435-2.348c-.059-1.335-.074-1.735-.074-5.112s.015-3.782.074-5.112a6.933,6.933,0,0,1,.435-2.348A3.846,3.846,0,0,1,4.2,3.752a3.928,3.928,0,0,1,1.453-.944A6.966,6.966,0,0,1,8,2.373C9.338,2.314,9.739,2.3,13.115,2.3s3.782.015,5.112.074a6.936,6.936,0,0,1,2.348.435,3.891,3.891,0,0,1,1.453.944,3.934,3.934,0,0,1,.944,1.454,6.965,6.965,0,0,1,.435,2.348c.059,1.335.074,1.735.074,5.112S23.467,16.438,23.408,17.773Zm0,0" transform="translate(-0.449 0)"/>
      <path id="Caminho_74" dataname="Caminho 74" d="M131.45,124.5a6.5,6.5,0,1,0,6.5,6.5A6.5,6.5,0,0,0,131.45,124.5Zm0,10.718A4.217,4.217,0,1,1,135.667,131,4.218,4.218,0,0,1,131.45,135.218Zm0,0" transform="translate(-118.794 -118.345)"/>
      <path id="Caminho_75" dataname="Caminho 75" d="M365.485,90.119a1.518,1.518,0,1,1-1.518-1.518A1.518,1.518,0,0,1,365.485,90.119Zm0,0" transform="translate(-344.553 -84.221)"/>
    </svg>
  )
}
