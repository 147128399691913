import React, { useEffect, useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { SearchIcon } from '../../icons'
import { setLocalStorage, getLocalStorage } from '../../helpers/localStorage'
import getContentfulTags from '../../services/api/tags'
import logoImage from '../../assets/ilovee_logo.png'
import iloveeConnectingImage from '../../assets/ilovee_connecting.png'
import connectingCircle from '../../assets/connecting_circle.png'
import styles from './style.module.scss'

export const Navbar = () => {
	const [ categories, setCategories ] = useState([])
  const [ mobileMenu, setMobileMenu ] = useState(false)
  const [ searchMenu, setSearchMenu ] = useState(false)
  const [ searchValue, setSearchValue ] = useState()

  const searchInput = useRef()
  const history = useHistory()

	useEffect(() => {
		// Calling categories from contentful
    const listTags = async () => {
      if ( getLocalStorage('categories') )
        return setCategories(getLocalStorage('categories'))

      const tags = await getContentfulTags()
      setCategories(tags.items)
      setLocalStorage('categories', tags.items, 60)
    }
    listTags()
	}, [])

	return (
		<nav className={styles.navbar}>
			<div className={`${styles['navbar-desk']} container`}>
				<div className={`d-flex w-100 align-center ${styles.navbar__primary}`}>
					<div className={`w-100 ${styles.navbar__logo}`}>
            <Link to="/">
              <img
                src={logoImage}
                alt="Logo escrito ilovee serifado e em tom de preto"
                width="289"
                height="67"
              />
            </Link>
					</div>
					<form
            method="POST"
            onSubmit={(e) => {
              e.preventDefault()
              if ( !searchValue ) return
              window.location.href = `/pesquisa/${searchValue}`
            }}
            className={styles.navbar__search}
          >
						<div className={styles.input__group}>
							<SearchIcon className={styles.input__addon} />
							<input type="text" name="search" id="search" autoComplete="off" onChange={(e) => setSearchValue(e.target.value)}/>
							<label htmlFor="search">Busca</label>
						</div>
					</form>
				</div>
				<div className={`d-flex w-100 align-center ${styles.navbar__secondary}`}>
					<ul className={`d-flex ${styles.navbar__items}`}>
            {
              categories.map(cat => {
                return <li key={cat?.sys.id}>
                         <a href={cat.name === "Matérias" ? '/materias' : `/materias/${cat?.sys.id}`}>{cat.name}</a>
                       </li>
              })
            }
					</ul>
					<div className={styles.navbar__image}>
						<img
							src={iloveeConnectingImage}
							alt="Logo escrito connecting serifado e em tom de preto"
							width="175"
							height="41"
              className={styles['navbar__image-primary']}
						/>
            <img
              src={connectingCircle}
              alt="Logo escrito ilovee serifado e em tom de preto"
              width="111"
              height="104"
              className={`${styles['navbar__image-secondary']}`}
            />
					</div>
				</div>
			</div>

      <div className={styles['navbar-mobile']}>
        <div className={styles['navbar-mobile__col']}>
          <p onClick={() => {setMobileMenu(false); setSearchMenu(!searchMenu); !searchMenu && searchInput.current.focus()}}>Busca</p>
        </div>
        <div className={`${styles['navbar-mobile__col']} justify-center align-center`}>
          <Link to="/">
            <img
              src={logoImage}
              alt="Logo escrito ilovee serifado e em tom de preto"
              width="139"
              height="34"
            />
          </Link>
        </div>
        <div className={`${styles['navbar-mobile__col']} justify-right align-center`}>
          <a
            href={"openmenu"}
            className={`
              ${styles['hamburger']}
              ${mobileMenu ? styles['hamburger--active'] : ''}
            `}
            onClick={(e) => {
              e.preventDefault();
              setMobileMenu(!mobileMenu);
              setSearchMenu(false)
            }}>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>

        <div className={`
          ${styles['navbar-mobile__menu']}
          ${mobileMenu ? styles['navbar-mobile__menu--active'] : ''}
        `}>
          <ul>
          {
              categories.map(cat => {
                return <li key={`mobile_${cat?.sys.id}`}>
                         <a href={`/materias/${cat?.sys.id}`} className={styles.link}>{cat.name}</a>
                       </li>
              })
            }
          </ul>
        </div>

        <div
          className={`
            ${styles['navbar-mobile__search']}
            ${searchMenu ? styles['navbar-mobile__search--active'] : ''}
          `}
        >
          <form
            method="POST"
            onSubmit={(e) => {
              e.preventDefault()
              if ( !searchValue ) return
              window.location.href = `/pesquisa/${searchValue}`
            }}
          >
            <input ref={searchInput} type="text" placeholder="Busca" onChange={(e) => setSearchValue(e.target.value)}/>
            <SearchIcon />
          </form>
        </div>

        <div
          className={
            `${styles['navbar-mobile__backdrop']}
            ${mobileMenu || searchMenu ? styles['navbar-mobile__backdrop--active'] : ''}
          `}
          onClick={() => {
            setMobileMenu(false);
            setSearchMenu(false);
          }}
        ></div>
      </div>
		</nav>
	)
}
